import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, EffectFade } from "swiper";
import banner1 from "../../assets/images/myltf/IMG_5214.jpg";
import banner2 from "../../assets/images/myltf/IMG_9647.jpg";
import banner3 from "../../assets/images/myltf/IMG_9627.jpg";

SwiperCore.use([Autoplay, Pagination, EffectFade]);

const MainSliderTwo = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    pagination: {
      el: "#main-slider-pagination",
      type: "bullets",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <section className="main-slider main-slider__two">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url(${banner1})` }}
          ></div>

          <Container>
            <Row className=" justify-content-start">
              <Col xl={6} lg={12} className="text-left">
                <h1>
                  Made You Love Tennis <br /> Foundation
                </h1>
                <p>
                  We develop youth through tennis life skills of excellence both
                  on and off the court
                </p>
                <a
                  href="/donate"
                  data-target=".donate-options"
                  className="scroll-to-target thm-btn dynamic-radius"
                >
                  Donate Now
                </a>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url(${banner2})` }}
          ></div>

          <Container>
            <Row className=" justify-content-start">
              <Col xl={6} lg={12} className="text-left">
                <h2>We Aim To Serve and To Love</h2>
                <p>
                  We aim to impact underserved youth using tennis as a tool for
                  education, tennis scholarship and career opportunities
                </p>
                <a
                  href="/mission"
                  className="scroll-to-target thm-btn dynamic-radius"
                >
                  Our Mission
                </a>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url(${banner3})` }}
          ></div>

          <Container>
            <Row className="justify-content-start">
              <Col xl={6} lg={12} className="text-left">
                <h2>Making a Difference in our Community</h2>
                <p>
                  Reach out to become a volunteer or partner to help give back
                  to our Atlanta community
                </p>
                <a
                  href="/contact"
                  data-target=".donate-options"
                  className="scroll-to-target thm-btn dynamic-radius"
                >
                  Contact Us
                </a>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
        <div className="swiper-pagination" id="main-slider-pagination"></div>
      </Swiper>
    </section>
  );
};

export default MainSliderTwo;
