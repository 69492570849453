import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import welcomeImage from "../../assets/images/myltf/IMG_7359.jpg"
// import aboutImage from "../../assets/images/myltf/IMG_9619.jpg"

const AboutTwo = () => {
  return (
    <section className="about-two pt-120 pb-120">
      <Container>
        <Row>
          <Col xl={6}>
            <div className="about-two__image">
              <img src={welcomeImage} alt="" className="welcome-image"/>
              {/* <div className="about-two__award">
                <img src={aboutImage} alt="" />
              </div> */}
            </div>
          </Col>
          <Col xl={6}>
            <div className="about-two__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" /> About MYLTF
                </p>
                <h3>The Made You Love Tennis Foundation</h3>
              </div>
              <p className="mb-40 pr-10">
                We are a nonprofit organization that provides holistic tennis
                education, health and wellness, mentoring, and outreach to
                impact the underserved youth of Atlanta and in underinvested
                communities.
              </p>
              <Row>
                <Col md={6}>
                  <div className="about-two__box">
                    <h3>
                      <i className="azino-icon-confirmation"></i> Make a Donation
                    </h3>
                    <p>
                      As a 501(c)(3) nonprofit, our work is funded by your donations.
                    </p>
                  </div>
                  <div className="about-two__box">
                    <h3>
                      <i className="azino-icon-confirmation"></i> Volunteer with Us
                    </h3>
                    <p>
                      Join us on the court and in schools as we give back to our community.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="about-two__box-two">
                    <i className="azino-icon-support"></i>
                    <h3>You can make a big difference in our organization.</h3>
                  </div>
                </Col>
              </Row>
              <Link className="thm-btn dynamic-radius" to="/donate">
                Donate Now
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
