import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col } from "react-bootstrap";
import heartImage from "../../assets/images/shapes/Green-ball.png";
import causeImage1 from "../../assets/images/stock/AdobeStock_374849081_Preview.jpeg"
import causeImage2 from "../../assets/images/stock/AdobeStock_119098395_Preview.jpeg"
import causeImage3 from "../../assets/images/stock/AdobeStock_213925805_Preview.jpeg"
import causeImage4 from "../../assets/images/stock/AdobeStock_422257359_Preview.jpeg"

const CausesHomeData = [
  {
    image: causeImage1,
    progressCount: 48,
    raised: "25,270",
    goal: "30,000",
    title: 'Have "A" average grades',
    text: "Extracurriculars like tennis also boost performance in the classroom.",
    link: "/cause-details",
  },
  {
    image: causeImage2,
    progressCount: 81,
    raised: "25,270",
    goal: "30,000",
    title: "Will attend universities",
    text: "Better grades and sports mean students have a better chance at getting into college.",
    link: "/cause-details",
  },
  {
    image: causeImage3,
    progressCount: 73,
    raised: "25,270",
    goal: "30,000",
    title: "Never sent to the principal",
    text: "More involvement with their community means students contribute more to those around them.",
    link: "/cause-details",
  },
  {
    image: causeImage4,
    progressCount: 82,
    raised: "25,270",
    goal: "30,000",
    title: "Volunteer in communities",
    text: "Students who participate in extracurriculars volunteer and give back to their community.",
    link: "/cause-details",
  },
];

const CausesHome = () => {
  const swiperParams = {
    slidesPerView: 4,
    spaceBetween: 30,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      375: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      575: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1199: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  };
  return (
    <section className="causes-page causes-home pt-120 pb-120">
      <Container>
        <Row className=" align-items-start align-items-md-center flex-column flex-md-row mb-60">
          <Col lg={7}>
            <div className="block-title">
              <p>
                <img src={heartImage} width="15" alt="" />
                About Tennis
              </p>
              <h3>
                Why should youth <br /> learn and play tennis?
              </h3>
            </div>
          </Col>
          <Col lg={5} className=" d-flex">
            <div className="my-auto">
              <p className="block-text pr-10 mb-0">
                Extracurricular activities like tennis help youth form a sense
                of belonging and community while engaging their creativity,
                playfulness and physical skills. Here are some facts about
                playing tennis in school:
              </p>
            </div>
          </Col>
        </Row>
        <Swiper {...swiperParams}>
          {CausesHomeData.map(
            (
              { image, progressCount, raised, goal, title, text, link },
              index
            ) => (
              <SwiperSlide key={`cause-card-key-${index}`}>
                <div className="cause-card">
                  <div className="cause-card__inner">
                    <div className="cause-card__image">
                      <img src={image} alt="" />
                    </div>
                    <div className="cause-card__content">
                      <div className="cause-card__top">
                        <div className="cause-card__progress">
                          <span
                            style={{ width: `${progressCount}%` }}
                            className="wow cardProgress"
                            data-wow-duration="1500ms"
                          >
                            <b>
                              <i>{progressCount}</i>%
                            </b>
                          </span>
                        </div>
                        {/* <div className="cause-card__goals">
                          <p>
                            <strong>Raised:</strong> ${raised}
                          </p>
                          <p>
                            <strong>Goal:</strong> ${goal}
                          </p>
                        </div> */}
                      </div>
                      <h3>
                        {/* <Link to={link}>{title}</Link> */}
                        {title}
                      </h3>
                      <p>{text}</p>
                      {/* <div className="cause-card__bottom">
                        <Link className="thm-btn " to={link}>
                          Donate Now
                        </Link>
                        <Link className="cause-card__share" to="#">
                          <i className="azino-icon-share"></i>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Container>
    </section>
  );
};

export default CausesHome;
