import React, { useState } from "react";
import { Link } from "gatsby";
import ModalVideo from "react-modal-video";
import { Container } from "react-bootstrap";
import bgImage from "../../assets/images/myltf/IMG_9683.jpg"
import heart from "../../assets/images/shapes/Green-ball.png";

const VideoCard = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="video-card">
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="pAp9MMr7lE4"
        start="70"
        onClose={() => setOpen(false)}
      />
      <div
        className="video-card__bg"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>

      <Container className=" text-center pt-120 pb-120">
        <p>
          <img src={heart} width="15" alt="" />
          Our Founder
        </p>
        <h3>
          To Serve and To Love, <br />
          changing lives one shot at a time.
        </h3>
        <div className="video-card__btn-block">
          <Link to="/about/founder" className="thm-btn dynamic-radius">
            Learn More
          </Link>

          <span
            onClick={() => setOpen(true)}
            onKeyDown={() => setOpen(true)}
            className="video-popup video-card__btn"
            role="button"
            tabIndex={0}
          >
            <i className="fa fa-play"></i>
          </span>
        </div>
      </Container>
    </section>
  );
};

export default VideoCard;
