// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../assets/images/shapes/Green-ball.png";
import PaypalButton from "../components/PaypalButton";

const DonationOptions = () => {
  const [donationAmount, setDonationAmount] = React.useState(20);
  // const [{ options }, dispatch] = usePayPalScriptReducer();

  const handleUpdateDonationAmount = (event, amount) => {
    event.preventDefault();
    setDonationAmount(amount);
    // dispatch({
    //   type: "resetOptions",
    //   value: {
    //     ...options,
    //     amount,
    //   },
    // });
  };
  return (
    <section className="donate-options pt-120">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  Donate Now
                </p>
                <h3>Contribute to tennis in your community</h3>
              </div>
              <p>
                Make a donation to see your money make an impact <br /> on youth
                and communities in Atlanta. Your contribution <br /> directly
                funds our programs, services, and classes that bring <br />{" "}
                tennis to underserved communities in our city.
              </p>
              <div className="donate-options__call">
                <i className="azino-icon-telephone"></i>
                <div className="donate-options__call-content">
                  <p>
                    Have questions about your donation? <br />{" "}
                    <span>Call us now:</span>{" "}
                    <a href="tel:404-918-6010">404-918-6010</a>
                  </p>
                </div>
              </div>
              <div className="donate-options__icon-wrap">
                <div className="donate-options__icon">
                  <i className="azino-icon-water-bottle"></i>
                  <h3>
                    <a href="#none">Tennis</a>
                  </h3>
                </div>
                <div className="donate-options__icon">
                  <i className="azino-icon-reading-book"></i>
                  <h3>
                    <a href="#none">Education</a>
                  </h3>
                </div>
                <div className="donate-options__icon">
                  <i className="azino-icon-family"></i>
                  <h3>
                    <a href="#none">Community</a>
                  </h3>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <form
              action="#"
              className="donate-options__form wow fadeInUp"
              data-wow-duration="1500ms"
            >
              <h3 className="text-center">Make a donation</h3>
              <p className="text-center">
                Click below to make a donation using our Paypal portal.
              </p>
              {/* <p className="text-center">
                Enter or select your donation amount <br /> to visit our PayPal
                donation portal.
              </p> */}
              {/* <input type="text" id="donate-name" placeholder="Your Name" />
              <input
                type="text"
                placeholder="Insert Value"
                id="donate-amount"
              /> */}
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="H7TZ63QB6JR62"
                />
                <input
                  type="image"
                  src="https://pics.paypal.com/00/s/OGRhOTFjYmUtNDY1Zi00MTNlLWIwYmQtOWYzM2E3OTBkNzdm/file.PNG"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>

              {/* <ul id="donate-amount__predefined" className="list-unstyled">
                <li>
                  <button onClick={(e) => handleUpdateDonationAmount(e, 10)}>
                    $10
                  </button>
                </li>
                <li>
                  <button onClick={(e) => handleUpdateDonationAmount(e, 20)}>
                    $20
                  </button>
                </li>
                <li>
                  <button onClick={(e) => handleUpdateDonationAmount(e, 50)}>
                    $50
                  </button>
                </li>
              </ul>
              <PaypalButton
                amount={donationAmount}
                paypalStyle={{
                  shape: "pill",
                  color: "silver",
                  layout: "vertical",
                  label: "paypal",
                }}
                amount={10}
              /> */}
              {/* <button type="submit" className="thm-btn donation-btn">
                Donate Now
              </button> */}
              {/* <div className={ donationAmount === 10 ? `` : "hidden-element" }
              >
                <PaypalButton
                  amount={donationAmount}
                  paypalStyle={{
                    shape: "pill",
                    color: "silver",
                    layout: "vertical",
                    label: "paypal",
                  }}
                  amount={10}
                />
              </div>
              <div className={ donationAmount === 20 ? `` : "hidden-element" }
              >
                <PaypalButton
                  amount={donationAmount}
                  paypalStyle={{
                    shape: "pill",
                    color: "silver",
                    layout: "vertical",
                    label: "paypal",
                  }}
                  amount={20}
                />
              </div>
              <div className={ donationAmount === 50 ? `` : "hidden-element" }
              >
                <PaypalButton
                  amount={donationAmount}
                  paypalStyle={{
                    shape: "pill",
                    color: "silver",
                    layout: "vertical",
                    label: "paypal",
                  }}
                  amount={50}
                />
              </div> */}
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DonationOptions;
